import React from "react";
import "../styles/contact-form.sass";

class ContactForm extends React.Component {
  render() {
    const formStyle = {
      textAlign: "left",
    };
    const buttonsStyle = {
      margin: "1rem",
      textAlign: "center",
    };
    return (
      <form
        style={formStyle}
        className="form"
        action={this.props.action}
        method="post"
      >
        <div>
          <label>Full Name</label>
          <input type="text" name="fullname" />
        </div>
        <div>
          <label>Email</label>
          <input type="email" name="email" />
        </div>
        <div>
          <label>Message</label>
          <textarea name="message" rows="5"></textarea>
        </div>
        <ul className="actions" style={buttonsStyle}>
          <li>
            <button type="submit" className="button_special">
              Send
            </button>
            <input type="hidden" name="_recaptcha" id="_recaptcha" />
            <script src="https://www.google.com/recaptcha/api.js?render=6Lf7UsoUAAAAACT2Z6gLyh7RTDfyYGxfZ-M4D0ph"></script>
            <script src="https://www.flexyform.com/js/recaptcha.js"></script>
          </li>
        </ul>
      </form>
    );
  }
}

export default ContactForm;
