import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
// import Img from "gatsby-image";
import "../styles/blocks/footer.sass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import ContactForm from "../components/ContactForm";

const Footer = ({ data }) => {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          datoCmsHome {
            introTextNode {
              childMarkdownRemark {
                html
              }
            }
            copyright
            telephoneNumber
            email
          }
          allDatoCmsSocialProfile(sort: { fields: [position], order: ASC }) {
            edges {
              node {
                profileType
                url
              }
            }
          }
        }
      `}
      render={(data) => (
        <footer>
          <div className="footer-container">
            <div className="footer-social-container">
              <h4>Follow Us</h4>
              <div className="footer-social">
                {data.allDatoCmsSocialProfile.edges.map(({ node: profile }) => (
                  <a
                    key={profile.profileType}
                    href={profile.url}
                    target="blank"
                    className={`social social--${profile.profileType.toLowerCase()}`}
                  >
                    {" "}
                  </a>
                ))}
              </div>
            </div>
            <div className="footer-menu-container">
              <h4>Menu</h4>
              <ul className="footer-menu">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/projects">Projects</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/testimonials">Testimonials</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
            <div className="contact">
              <h4>Get In Touch</h4>
              <div className="footer-email">
                <FontAwesomeIcon icon={faEnvelope} />
                <a href={"mailto:" + data.datoCmsHome.email}>
                  {data.datoCmsHome.email}
                </a>
              </div>
              <div className="footer-phone">
                <FontAwesomeIcon icon={faPhone} />
                <a href={"tel:" + data.datoCmsHome.telephoneNumber}>
                  {data.datoCmsHome.telephoneNumber}
                </a>
              </div>
            </div>
            <section id="contactUs" className="main special">
              <header className="major">
                <h4>Contact Us</h4>
                <ContactForm action="https://www.flexyform.com/f/8f8e26499313c73c147450664516feb8506eef46"></ContactForm>
              </header>
            </section>
          </div>
          <div className="footer-copyright-container">
            <div className="footer-copyright">{data.datoCmsHome.copyright}</div>
          </div>
        </footer>
      )}
    />
  );
};
export default Footer;
